import React from 'react';

import Trans from '@components/Trans';
import DeskoptBr from '@components/ui/DeskoptBr';
import Underline from '@components/ui/Underline';

import clsx from 'clsx';

import styles from './ExpertSupport.module.css';
import imgAVIF from './assets/img@1x.avif';
import imgPNG from './assets/img@1x.png';
import imgWebP1x from './assets/img@1x.webp';
import imgAVIF2x from './assets/img@2x.avif';
import imgPNG2x from './assets/img@2x.png';
import imgWebP2x from './assets/img@2x.webp';

interface ExpertSupportProps {
    classNames?: Record<string, string>;
    title?: JSX.Element;
    description?: JSX.Element;
    image?: JSX.Element;
    items?: {
        preName: JSX.Element;
        name: JSX.Element;
        desc: JSX.Element;
    }[];
}

const ns = 'pages/max/design2024/components/SectionExpertSupport/index';

const ExpertSupport = ({ title, description, image, items, classNames }: ExpertSupportProps) => {
    const defaultTitle = title ? (
        title
    ) : (
        <Trans
            i18nKey="sectionTitle"
            components={{
                span: <span />,
                br: <DeskoptBr />,
            }}
            ns={ns}
        />
    );

    const defaultDescription = description ? (
        description
    ) : (
        <Trans
            i18nKey="sectionDescription"
            components={{
                span: <span />,
                br: <DeskoptBr />,
            }}
            ns={ns}
        />
    );

    const defaultImage = image ? (
        image
    ) : (
        <picture>
            <source
                type="image/avif"
                srcSet={`${imgAVIF.src} 1x, ${imgAVIF2x.src} 2x`}
            />
            <source
                type="image/webp"
                srcSet={`${imgWebP1x.src} 1x, ${imgWebP2x.src} 2x`}
            />
            <source srcSet={`${imgPNG.src} 1x, ${imgPNG2x.src} 2x`} />
            <img
                width={imgPNG.width}
                height={imgPNG.height}
                decoding="async"
                loading="lazy"
                alt=""
                src={imgPNG.src}
            />
        </picture>
    );

    const defaultItems = items
        ? items
        : [
              {
                  preName: (
                      <Trans
                          i18nKey="item1preName"
                          components={{
                              u: (
                                  <Underline
                                      variant="max-orange"
                                      size="small"
                                  />
                              ),
                          }}
                          ns={ns}
                      />
                  ),
                  name: (
                      <Trans
                          i18nKey="item1name"
                          components={{
                              span: <span />,
                          }}
                          ns={ns}
                      />
                  ),
                  desc: (
                      <Trans
                          i18nKey="item1desc"
                          components={{
                              span: <span />,
                              br: <DeskoptBr />,
                          }}
                          ns={ns}
                      />
                  ),
              },
              // 2
              {
                  preName: (
                      <Trans
                          i18nKey="item2preName"
                          components={{
                              u: (
                                  <Underline
                                      variant="max-orange"
                                      size="small"
                                  />
                              ),
                          }}
                          ns={ns}
                      />
                  ),
                  name: (
                      <Trans
                          i18nKey="item2name"
                          components={{
                              span: <span />,
                          }}
                          ns={ns}
                      />
                  ),
                  desc: (
                      <Trans
                          i18nKey="item2desc"
                          components={{
                              span: <span />,
                              br: <DeskoptBr />,
                          }}
                          ns={ns}
                      />
                  ),
              },
              // 3
              {
                  preName: (
                      <Trans
                          i18nKey="item3preName"
                          components={{
                              u: (
                                  <Underline
                                      variant="max-orange"
                                      size="small"
                                  />
                              ),
                          }}
                          ns={ns}
                      />
                  ),
                  name: (
                      <Trans
                          i18nKey="item3name"
                          components={{
                              span: <span />,
                          }}
                          ns={ns}
                      />
                  ),
                  desc: (
                      <Trans
                          i18nKey="item3desc"
                          components={{
                              span: <span />,
                              br: <DeskoptBr />,
                          }}
                          ns={ns}
                      />
                  ),
              },
              // 4
              {
                  preName: (
                      <Trans
                          i18nKey="item4preName"
                          components={{
                              u: (
                                  <Underline
                                      variant="max-orange"
                                      size="small"
                                  />
                              ),
                          }}
                          ns={ns}
                      />
                  ),
                  name: (
                      <Trans
                          i18nKey="item4name"
                          components={{
                              span: <span />,
                          }}
                          ns={ns}
                      />
                  ),
                  desc: (
                      <Trans
                          i18nKey="item4desc"
                          components={{
                              span: <span />,
                              br: <DeskoptBr />,
                          }}
                          ns={ns}
                      />
                  ),
              },
              // 5
              {
                  preName: (
                      <Trans
                          i18nKey="item5preName"
                          components={{
                              u: (
                                  <Underline
                                      variant="max-orange"
                                      size="small"
                                  />
                              ),
                          }}
                          ns={ns}
                      />
                  ),
                  name: (
                      <Trans
                          i18nKey="item5name"
                          components={{
                              span: <span />,
                          }}
                          ns={ns}
                      />
                  ),
                  desc: (
                      <Trans
                          i18nKey="item5desc"
                          components={{
                              span: <span />,
                              br: <DeskoptBr />,
                          }}
                          ns={ns}
                      />
                  ),
              },
              // 6
              {
                  preName: (
                      <Trans
                          i18nKey="item6preName"
                          components={{
                              u: (
                                  <Underline
                                      variant="max-orange"
                                      size="small"
                                  />
                              ),
                          }}
                          ns={ns}
                      />
                  ),
                  name: (
                      <Trans
                          i18nKey="item6name"
                          components={{
                              span: <span />,
                          }}
                          ns={ns}
                      />
                  ),
                  desc: (
                      <Trans
                          i18nKey="item6desc"
                          components={{
                              span: <span />,
                              br: <DeskoptBr />,
                          }}
                          ns={ns}
                      />
                  ),
              },
          ];

    return (
        <>
            <div className={clsx(styles.header, classNames?.['header'])}>
                <h2 className={clsx(styles.title, classNames?.['title'])}>{defaultTitle}</h2>
                <p className={clsx(styles.description, classNames?.['description'])}>{defaultDescription}</p>
            </div>
            <div className={clsx(styles['grid-container'], classNames?.['grid-container'])}>
                <div className={clsx(styles.col1, classNames?.['col1'])}>{defaultImage}</div>
                <div className={clsx(styles.col2, styles['flex-container'], classNames?.['col2'])}>
                    {defaultItems.map((item, index) => {
                        return (
                            <div
                                className={clsx(styles['flex-item'], classNames?.['flex-item'])}
                                key={index}
                            >
                                <p className={clsx(styles.preName, classNames?.['preName'])}>{item.preName}</p>
                                <p className={clsx(styles.name, classNames?.['name'])}>{item.name}</p>
                                <p className={clsx(styles.desc, classNames?.['desc'])}>{item.desc}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default ExpertSupport;
