import Trans from '@components/Trans';
import Container from '@components/global/Container';
import DeskoptBr from '@components/ui/DeskoptBr';
import { Button } from '@components/ui/design2023/Button';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import styles from './AnalysisBusinessBenefits.module.css';
import { ReactComponent as SVG1 } from './assets/ico_01.svg';
import { ReactComponent as SVG2 } from './assets/ico_02.svg';
import { ReactComponent as SVG3 } from './assets/ico_03.svg';
import { ReactComponent as SVG4 } from './assets/ico_04.svg';
import { ReactComponent as Logo } from './assets/logo.svg';
import ItemBenefits from './components/ItemBenefits';

const ns = 'pages/max/design2024/components/AnalysisBusinessBenefits/index';

export interface ContentProps {
    sectionHeadline?: JSX.Element | string;
    itemsElementBenefits?: JSX.Element[];
    businessDescription?: JSX.Element | string;
    businessButton?: JSX.Element;
    businessLogo?: JSX.Element;
}

export default function AnalysisBusinessBenefits({ sectionHeadline, itemsElementBenefits, businessDescription, businessButton, businessLogo }: ContentProps) {
    const { t } = useTranslation(ns);
    const sectionDefaultTitle = sectionHeadline ? (
        sectionHeadline
    ) : (
        <Trans
            i18nKey="sectionDefaultTitle"
            components={{
                span: <span />,
                br: <DeskoptBr />,
            }}
            ns={ns}
        />
    );
    const businessDefaultDesc = businessDescription ? (
        businessDescription
    ) : (
        <Trans
            i18nKey="businessDefaultDesc"
            components={{
                p: <p />,
                span: <span />,
                br: <DeskoptBr />,
            }}
            ns={ns}
        />
    );

    const businesDefaultButton = businessButton ? (
        businessButton
    ) : (
        <Button
            color="black-white"
            label={t('businesDefaultButtonLabel')}
            href="https://lp.getresponse.com/forrester-study"
        />
    );

    const businesDefaultLogo = businessLogo ? (
        businessLogo
    ) : (
        <Logo
            width={151}
            height={24}
        />
    );
    // Items
    const itemsBenefits = itemsElementBenefits ? (
        itemsElementBenefits
    ) : (
        <>
            <div className={styles.grid}>
                <ItemBenefits
                    styles={styles}
                    iconBenefit={
                        <SVG1
                            width={32}
                            height={32}
                        />
                    }
                    valueBenefit={
                        <Trans
                            i18nKey="valueDefaultBenefitItem1"
                            components={{
                                span: <span />,
                            }}
                            ns={ns}
                        />
                    }
                    descBenefit={
                        <Trans
                            i18nKey="descDefaultBenefitItem1"
                            components={{
                                span: <span />,
                            }}
                            ns={ns}
                        />
                    }
                />
            </div>
            <div className={styles.grid}>
                <ItemBenefits
                    styles={styles}
                    iconBenefit={
                        <SVG2
                            width={32}
                            height={32}
                        />
                    }
                    valueBenefit={
                        <Trans
                            i18nKey="valueDefaultBenefitItem2"
                            components={{
                                span: <span />,
                            }}
                            ns={ns}
                        />
                    }
                    descBenefit={
                        <Trans
                            i18nKey="descDefaultBenefitItem2"
                            components={{
                                span: <span />,
                            }}
                            ns={ns}
                        />
                    }
                />
            </div>
            <div className={styles.grid}>
                <ItemBenefits
                    styles={styles}
                    iconBenefit={
                        <SVG3
                            width={32}
                            height={32}
                        />
                    }
                    valueBenefit={
                        <Trans
                            i18nKey="valueDefaultBenefitItem3"
                            components={{
                                span: <span />,
                            }}
                            ns={ns}
                        />
                    }
                    descBenefit={
                        <Trans
                            i18nKey="descDefaultBenefitItem3"
                            components={{
                                span: <span />,
                            }}
                            ns={ns}
                        />
                    }
                />
            </div>
            <div className={styles.grid}>
                <ItemBenefits
                    styles={styles}
                    iconBenefit={
                        <SVG4
                            width={32}
                            height={32}
                        />
                    }
                    valueBenefit={
                        <Trans
                            i18nKey="valueDefaultBenefitItem4"
                            components={{
                                span: <span />,
                            }}
                            ns={ns}
                        />
                    }
                    descBenefit={
                        <Trans
                            i18nKey="descDefaultBenefitItem4"
                            components={{
                                span: <span />,
                            }}
                            ns={ns}
                        />
                    }
                />
            </div>
        </>
    );

    return (
        <section className={styles['analysis-business-benefits']}>
            <Container maxWidth="1130px">
                <div className={styles.header}>
                    <h2>{sectionDefaultTitle}</h2>
                </div>

                <div className={styles.columns}>
                    <div className={clsx(styles.col, styles.col1)}>{itemsBenefits}</div>
                    <div className={clsx(styles.col, styles.col2)}>
                        <div className={clsx(styles.grids, styles['grids-col2'])}>
                            <div className={styles.desc}>{businessDefaultDesc}</div>
                            <div className={styles.footer}>
                                <div className={styles.read}>{businesDefaultButton}</div>
                                <div className={styles.logo}>{businesDefaultLogo}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}
