import React, { FunctionComponent, cloneElement } from 'react';

import ExpertSupport from '@components/pages/max/design2024/components/ExpertSupport';

import clsx from 'clsx';

import styles from './Support.module.css';

interface SupportProps {
    container: React.ReactElement;
    customClass?: string;
}

const Content: FunctionComponent = () => {
    return (
        <div className={styles['section-expert-support']}>
            <ExpertSupport />
        </div>
    );
};

export default function SectionExpertSupport({ container, customClass }: SupportProps): JSX.Element {
    return (
        <section className={clsx(styles['brands'], customClass)}>
            {cloneElement(container, {
                children: <Content />,
                maxWidth: '1180px',
            })}
        </section>
    );
}
