import React, { FunctionComponent, cloneElement } from 'react';

import BrandsMax from '@components/global/Brands';

import clsx from 'clsx';

import styles from './Brands.module.css';

interface BrandsProps {
    container: React.ReactElement;
    customClass?: string;
}

const Content: FunctionComponent = () => {
    return <BrandsMax colorVersion={'white'} />;
};

export default function Brands({ container, customClass }: BrandsProps): JSX.Element {
    return (
        <section className={clsx(styles['brands'], customClass)}>
            {cloneElement(container, {
                children: <Content />,
            })}
        </section>
    );
}
