import React, { FunctionComponent, cloneElement } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import Hero from '@components/pages/max/design2024/components/Hero';
import DeskoptBr from '@components/ui/DeskoptBr';
import Underline from '@components/ui/Underline';
import { Button } from '@components/ui/design2023/Button';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteMaxBookADemo } from '@static_components/laravelLinks';

import styles from './Hero.module.css';

const HeroEn = dynamic(() => import('./assets/en'), {
    ssr: true,
});
const HeroDe = dynamic(() => import('./assets/de'), {
    ssr: true,
});
const HeroFr = dynamic(() => import('./assets/fr'), {
    ssr: true,
});
const HeroEs = dynamic(() => import('./assets/es'), {
    ssr: true,
});
const HeroIt = dynamic(() => import('./assets/it'), {
    ssr: true,
});
const HeroPt = dynamic(() => import('./assets/pt'), {
    ssr: true,
});
const HeroPl = dynamic(() => import('./assets/pl'), {
    ssr: true,
});

const ns = 'pages/max/design2024/components/SectionHero/index';

interface HeroProps {
    container: React.ReactElement;
}

const Content: FunctionComponent = () => {
    const { t } = useTranslation(ns);
    const { locale } = useRouter();

    return (
        <Hero
            title={
                <Trans
                    i18nKey="title"
                    components={{
                        u: <Underline variant="max-orange" />,
                        br: <DeskoptBr />,
                    }}
                    ns={ns}
                />
            }
            subtitle={
                <Trans
                    i18nKey="subtitle"
                    components={{
                        strong: <strong />,
                        br: <DeskoptBr />,
                    }}
                    ns={ns}
                />
            }
            button={
                <Button
                    label={t('BTNGetADemo')} //Get a Demo
                    color="max-orange"
                    size="medium"
                    href={`${laravelRouteMaxBookADemo(locale)}`}
                />
            }
            image={
                locale === 'de' ? (
                    <HeroDe />
                ) : locale === 'es' ? (
                    <HeroEs />
                ) : locale === 'fr' ? (
                    <HeroFr />
                ) : locale === 'it' ? (
                    <HeroIt />
                ) : locale === 'pt' ? (
                    <HeroPt />
                ) : locale === 'pl' ? (
                    <HeroPl />
                ) : locale === 'fr' ? (
                    <HeroFr />
                ) : (
                    <HeroEn />
                )
            }
        />
    );
};

export default function SectionHero({ container }: HeroProps): JSX.Element {
    return (
        <section className={styles.hero}>
            {cloneElement(container, {
                children: <Content />,
                maxWidth: '1180px',
            })}
        </section>
    );
}
