import React from 'react';

import styles from './CustomTab.module.css';

interface CustomTabProps {
    article: JSX.Element;
    picture: JSX.Element;
}

export default function CustomTab({ article, picture }: CustomTabProps): JSX.Element {
    return (
        <div className={styles.split}>
            <div className={styles.picture}>{picture}</div>
            <div className={styles.details}>{article}</div>
        </div>
    );
}
