// import styles from './ItemBenefits.module.css';

interface ItemBenefitsProps {
    iconBenefit: JSX.Element;
    valueBenefit: JSX.Element | string;
    descBenefit: JSX.Element | string;
    styles: Record<string, string>;
}

const ItemBenefits = ({ iconBenefit, valueBenefit, descBenefit, styles }: ItemBenefitsProps) => {
    return (
        <>
            <div className={styles.icon}>{iconBenefit}</div>
            <div className={styles.top}>
                <p>{valueBenefit}</p>
                <span>{descBenefit}</span>
            </div>
        </>
    );
};

export default ItemBenefits;
