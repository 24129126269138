import React from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

const TabEn = dynamic(() => import('./en'), {
    ssr: true,
});
const TabDe = dynamic(() => import('./de'), {
    ssr: true,
});
const TabFr = dynamic(() => import('./fr'), {
    ssr: true,
});
const TabEs = dynamic(() => import('./es'), {
    ssr: true,
});
const TabIt = dynamic(() => import('./it'), {
    ssr: true,
});
const TabPt = dynamic(() => import('./pt'), {
    ssr: true,
});
const TabPl = dynamic(() => import('./pl'), {
    ssr: true,
});

export default function TabPicture4() {
    const { locale } = useRouter();

    return <>{locale === 'de' ? <TabDe /> : locale === 'es' ? <TabEs /> : locale === 'fr' ? <TabFr /> : locale === 'it' ? <TabIt /> : locale === 'pt' ? <TabPt /> : locale === 'pl' ? <TabPl /> : locale === 'fr' ? <TabFr /> : <TabEn />}</>;
}
