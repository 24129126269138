import React from 'react';

import Head from 'next/head';

import useTranslation from '@hooks/useTranslation';

import Heroavif from './hero@1x.avif';
import Heroavif2x from './hero@2x.avif';

interface HeroImageProps {
    lazy?: boolean;
    fetchpriority?: boolean;
    classname?: string;
}

export default function HeroImage({ lazy = true, fetchpriority = false, classname }: HeroImageProps): JSX.Element {
    const ns = 'promo/black_friday_2024/max/sectionHero/index';
    const { t } = useTranslation(ns);

    return (
        <>
            <Head>
                <link
                    rel="preload"
                    fetchpriority="high" // eslint-disable-line
                    href={Heroavif.src}
                    type={'image/avif'}
                    as="image"
                    imageSrcSet={`${Heroavif.src} ${Heroavif.width}w, ${Heroavif2x.src} ${Heroavif2x.width}w`}
                    imageSizes={`(min-width: ${Heroavif.width}px) ${Heroavif.width}px, 100vw`}
                />
            </Head>
            <img
                className={classname ? classname : undefined}
                width={Heroavif.width}
                height={Heroavif.height}
                decoding="async"
                loading={lazy ? 'lazy' : undefined}
                fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                alt={t('imgAlt')}
                src={Heroavif.src}
            />
        </>
    );
}
