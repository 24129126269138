import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import DeskoptBr from '@components/ui/DeskoptBr';
import Underline from '@components/ui/Underline';
import { Button } from '@components/ui/design2023/Button';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteMaxBookADemo } from '@static_components/laravelLinks';

import styles from './SectionHero.module.css';
import HeroImage from './en';

const ns = 'promo/black_friday_2024/max/sectionHero/index';
export const HeroPromo = () => {
    const { t } = useTranslation(ns);
    const { locale } = useRouter();
    return (
        <section className={styles['section-hero']}>
            <div className={styles['wrapper-head']}>
                <p className={styles.preHead}>{t('preHead')}</p>
                <h2 className={styles.head}>{t('head')}</h2>
                <p className={styles.subHead}>
                    <Trans
                        i18nKey="subHead"
                        components={{
                            u: <Underline variant="max-orange" />,
                            br: <DeskoptBr />,
                        }}
                        ns={ns}
                    />
                </p>
            </div>
            <h2 className={styles.preHeader}>
                <Trans
                    i18nKey="preHeader"
                    components={{
                        span: <span />,
                        br: <DeskoptBr />,
                    }}
                    ns={ns}
                />
            </h2>
            <div className={styles.desc}>
                <Trans
                    i18nKey="desc"
                    components={{
                        p: <p />,
                        strong: <strong />,
                        br: <DeskoptBr />,
                    }}
                    ns={ns}
                />
            </div>
            <Button
                label={t('btnLabel')} //Get a Demo
                color="max-orange"
                size="large"
                href={`${laravelRouteMaxBookADemo(locale)}`}
                className={styles.btn}
            />
            <HeroImage
                lazy={false}
                classname={styles.img}
            />
        </section>
    );
};
