import React, { cloneElement } from 'react';

import AnalysisBusinessBenefits from '@components/pages/max/design2024/components/AnalysisBusinessBenefits';

import styles from './SectionCustomers.module.css';

const Content = () => {
    return <AnalysisBusinessBenefits />;
};

interface SectionAnalysisBusinessBenefitsProps {
    container: React.ReactElement;
}

export default function Hero({ container }: SectionAnalysisBusinessBenefitsProps): JSX.Element {
    return (
        <section className={styles.hero}>
            {cloneElement(container, {
                children: <Content />,
                maxWidth: '1180px',
            })}
        </section>
    );
}
